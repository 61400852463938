<template>
  <main class="home">
    <div v-if="!isLoading && !isPortrait">
      <!--
    //# Hero -->
      <section class="l-hero" data-scroll-section>
        <HeroPresentation />
      </section>
      <!--
    //# Approach -->
      <section class="l-approach" data-scroll-section>
        <h1 class="title title-general" data-scroll="fade-left">
          The Approach
        </h1>
        <p class="text" data-scroll="fade-left">
          At the heart of all our work is understanding garnered from our proprietary Diagnostic Process. Marrying a powerful intuitive capacity with validated tools for organizational, individual and management assessment, this approach often uncovers what other eyes have missed.
        </p>
        <router-link class="button" :to="{ name: 'approach' }" data-scroll="fade-left">Learn more</router-link>
      </section>
      <!--
    //# Work -->
      <section class="l-work" data-scroll-section>
        <div class="text-c">
          <h1 class="title title-general" data-scroll="fade-right">The Work</h1>
          <p class="text" data-scroll="fade-right">
            Skilled at operating on a multi-national, multi-cultural basis,
            <br />Ascentador never utilizes off-the-shelf approaches or service offerings.<br />Instead we generate bespoke solutions for clients
            <br />
            on the basis of deep data.
          </p>
        </div>
        <ul class="cards-list">
          <li class="c-card card-item" data-scroll="fade-up" v-for="(animation, index) in animations" :data-scroll-delay="animation.number" :key="index">
            <figure class="image-item">
              <lottie class="" :options="animation.options" />
            </figure>
            <h4 class="title-item" v-html="animation.text"></h4>
            <router-link class="button btn-small" :to="{ name: animation.path }">Review a case</router-link>
          </li>
        </ul>
      </section>
      <!--
    //# Markets -->
      <section class="l-markets" data-scroll-section>
        <MarketsGraphs />
      </section>
      <!--
    //# Journey -->
      <section class="l-journey" data-scroll-section>
        <div class="text-c">
          <h1 class="title title-general" data-scroll="fade-right">
            The Journey
          </h1>
          <p class="text" data-scroll="fade-right">
            Ascentador was founded in 2008 by a group of like-minded partners determined to reinvent the marketplace and has since evolved into a “open architecture” platform of independent experts who bring their capabilities to bear in whatever combinations and locations best serve our clients.
          </p>
          <router-link class="button" data-scroll="fade-right" :to="{ name: 'journey' }">Learn more</router-link>
        </div>
        <div class="sprite-c" data-scroll>
          <div class="sprite"></div>
        </div>
      </section>
      <!--
    //# Exchange -->
      <section class="l-exchange" data-scroll-section>
        <div class="text-c">
          <h1 class="title title-general" data-scroll="fade-left">
            The Exchange
          </h1>
          <p class="text" data-scroll="fade-left">
            Ascentador partners deliberately spend time listening, reading, talking and writing on a range of topics impacting careers, talent and the workplace. We welcome guest authors to participate in this exchange of ideas with us and with our followers.
          </p>
          <button class="button" @click="$refs.ExchangeModal.open()" data-scroll="fade-left">
            Share a perspective
          </button>
        </div>
        <div class="slider-c" data-scroll="fade-up">
          <ExchangeSwiper :articles="articles" />
        </div>
        <router-link class="button button-exchange" :to="{ name: 'exchange' }" data-scroll="fade-left">View all</router-link>
      </section>
      <!-- Modal Exchange -->
      <section class="l-modal l-modal-exchange">
        <ExchangeModal @perspective-was-send="perspectiveWasSend" @keep-scrolling="keepScrolling" @stop-scrolling="stopScrolling" ref="ExchangeModal" />
      </section>
      <!--
    //# Contact -->
      <section id="contact" class="l-contact" data-scroll-section>
        <h1 class="title title-general" data-scroll="fade-up">
          Contact/Connect
        </h1>
        <!-- prettier-ignore -->
        <ContactSwiper
        @open-clients-modal="$refs.ContactClientsModal.open()"
        @open-candidates-modal="$refs.ContactCandidatesModal.open()"
      />
        <!-- <label class="moderate" v-if="moderatedContact">
					This message will be checked by the admin.
				</label> -->
      </section>
      <!-- Modal Contact Clients -->
      <section class="l-modal l-modal-clients">
        <ContactClientsModal @keep-scrolling="keepScrolling" @stop-scrolling="stopScrolling" ref="ContactClientsModal" @contact-was-send="contactWasSend" />
      </section>
      <!-- Modal Contact Candidates -->
      <section class="l-modal l-modal-candidates">
        <ContactCandidatesModal @keep-scrolling="keepScrolling" @stop-scrolling="stopScrolling" ref="ContactCandidatesModal" @contact-was-send="contactWasSend" />
      </section>
    </div>
    <div class="loader" v-else>
      <transition name="fade">
        <Spinner />
      </transition>
    </div>
  </main>
</template>

<script>
import HeroPresentation from './HeroPresentation';
import MarketsGraphs from './MarketsGraphs';
import ExchangeSwiper from './ExchangeSwiper';
import ContactSwiper from './ContactSwiper';
import ExchangeModal from './ExchangeModal';
import ContactClientsModal from './ContactClientsModal';
import ContactCandidatesModal from './ContactCandidatesModal';
import axios from 'axios';
import Spinner from '@/components/Spinner';

import Lottie from 'vue-lottie';
import * as work01 from '@/assets/home/work/home-work-01.json';
import * as work02 from '@/assets/home/work/home-work-02.json';
import * as work03 from '@/assets/home/work/home-work-03.json';
import * as work04 from '@/assets/home/work/home-work-04.json';

export default {
  name: 'Home',
  props: {
    isPortrait: Boolean,
  },
  components: {
    HeroPresentation,
    MarketsGraphs,
    ExchangeSwiper,
    ContactSwiper,
    ExchangeModal,
    ContactClientsModal,
    ContactCandidatesModal,
    Spinner,
    lottie: Lottie,
  },
  metaInfo() {
    return {
      title: 'Ascentador',
      meta: [
        {
          name: 'description',
          content: 'At the heart of all our work is understanding garnered from our proprietary Diagnostic Process. Marrying a powerful intuitive capacity with validated tools for organizational, individual and management assessment, this approach often uncovers what other eyes have missed.',
        },
      ],
    };
  },
  data() {
    return {
      isVisible: true,
      isLoading: true,
      moderated: false,
      moderatedCandidate: false,
      moderatedContact: false,
      articles: [],
      API: process.env.VUE_APP_API,
      hero: [
        {
          title: 'We are <span>Ascentador</span>',
          image: '../assets/home/home-exchange-01.jpg',
        },
        {
          title: 'We are <span>problem solvers</span>',
          image: '../assets/home/home-exchange-01.jpg',
        },
        {
          title: 'Focused on <span>helping companies optimize</span>',
          image: '../assets/home/home-exchange-01.jpg',
        },
        {
          title: 'their most <span>valuable asset...</span>',
          image: '../assets/home/home-exchange-01.jpg',
        },
        {
          title: ' <span>Talent</span>',
          image: '../assets/home/home-exchange-01.jpg',
        },
        {
          title: 'When we see companies <span>succeeding</span>',
          image: '../assets/home/home-exchange-01.jpg',
        },
        {
          title: 'executives <span>advancing</span>',
          image: '../assets/home/home-exchange-01.jpg',
        },
        {
          title: 'or innovation <span>increasing</span>',
          image: '../assets/home/home-exchange-01.jpg',
        },
        {
          title: 'we see <span>Talent </br>on the rise</span>',
          image: '../assets/home/home-exchange-01.jpg',
        },
      ],
      animations: [
        {
          number: 1,
          playing: true,
          text: 'Executive<br />Recruitment',
          path: 'work.executive-recruitment',
          options: {
            animationData: work01.default,
            loop: true,
            autoplay: true,
          },
        },
        {
          number: 2,
          playing: true,
          text: 'Succession<br />Planning</h4>',
          path: 'work.succession-planning',
          options: {
            animationData: work02.default,
            loop: true,
            autoplay: true,
          },
        },
        {
          number: 3,
          playing: true,
          text: 'Transaction<br />Support</h4>',
          path: 'work.transaction-support',
          options: {
            animationData: work03.default,
            loop: true,
            autoplay: true,
          },
        },
        {
          number: 4,
          playing: true,
          text: 'Professional<br />Development</h4>',
          path: 'work.professional-development',
          options: {
            animationData: work04.default,
            loop: true,
            autoplay: true,
          },
        },
      ],
    };
  },
  methods: {
    info(value) {
      this.test = value;
    },
    perspectiveWasSend() {
      this.moderated = true;
    },
    contactWasSend() {
      this.moderatedContact = true;
    },
    async getSwiperContent() {
      try {
        const response = await axios.get(`${this.API}/article`);
        const recentContent = await axios.get(response.data.links.last);
        this.articles = recentContent.data.data.slice(0, 5);
        this.$emit('view-mounted');
      } catch {
        console.log('error del get swiper content');
      } finally {
        this.isLoading = false;
      }
    },
    stopScrolling() {
      this.$emit('stop-scrolling');
    },
    keepScrolling() {
      this.$emit('keep-scrolling');
    },
  },
  mounted() {
    this.getSwiperContent();
    // setTimeout(() => {
    //   let img = new Image();
    //   img.src = require(`@/assets/home/fullimage1.png`);
    //   img.onload = function() {
    //     init();
    //   };

    //   let canvas = document.querySelector('canvas');
    //   let ctx = canvas.getContext('2d');

    //   const scale = 1;
    //   const width = 247;
    //   const height = 127;
    //   const scaledWidth = scale * width;
    //   const scaledHeight = scale * height;

    //   function drawFrame(frameX, frameY, canvasX, canvasY) {
    //     ctx.drawImage(img, frameX * width, frameY * height, width, height, canvasX, canvasY, scaledWidth, scaledHeight);
    //   }

    //   const cycleLoop = [0, 1];
    //   let currentLoopIndex = 0;
    //   let frameCount = 0;
    //   let currentDirection = 0;

    //   function step() {
    //     frameCount++;
    //     if (frameCount < 56) {
    //       window.requestAnimationFrame(step);
    //       return;
    //     }
    //     frameCount = 0;
    //     ctx.clearRect(0, 0, canvas.width, canvas.height);
    //     drawFrame(cycleLoop[currentLoopIndex], currentDirection, 0, 0);
    //     currentLoopIndex++;
    //     if (currentLoopIndex >= cycleLoop.length) {
    //       currentLoopIndex = 0;
    //       currentDirection++;
    //     }
    //     if (currentDirection >= 4) {
    //       currentDirection = 0;
    //     }
    //     window.requestAnimationFrame(step);
    //   }

    //   function init() {
    //     window.requestAnimationFrame(step);
    //   }
    // }, 1000);
  },
};
</script>
