<template>
	<transition name="modal-fade">
		<div v-show="active" class="modal-bk">
			<div class="modal c-card">
				<header class="modal-header">
					<slot name="header">
						<button type="button" class="btn-close" @click="close"></button>
					</slot>
				</header>
				<section class="modal-body">
					<slot name="body">
						<form action="" @submit.prevent="onSubmit" class="form-c">
							<div class="field-c">
								<input
									class="field --input"
									v-model="perspective.name"
									placeholder="Name"
								/>
								<label class="error-label" v-if="error.name">{{
									error.name[0]
								}}</label>
							</div>
							<div class="field-c">
								<input
									class="field --input"
									v-model="perspective.email"
									placeholder="Email address"
								/>
								<label class="error-label" v-if="error.email">{{
									error.email[0]
								}}</label>
							</div>
							<div class="field-c">
								<input
									class="field --input"
									v-model="perspective.title"
									placeholder="Title"
								/>
								<label class="error-label" v-if="error.title">{{
									error.title[0]
								}}</label>
							</div>
							<div class="field-c">
								<textarea
									class="field --textarea"
									v-model="perspective.content"
									placeholder="Message"
								></textarea>
								<label class="error-label" v-if="error.content">{{
									error.content[0]
								}}</label>
							</div>
							<div class="input-group">
								<div class="field-c">
									<label class="error-label" v-if="error.file">{{
										error.file[0]
									}}</label>
									<label for="file" class="button btn-small btn-input --border"
										>Upload</label
									>
									<input id="file" ref="file" type="file" value="Upload file" />
								</div>
								<div class="field-c">
									<button
										class="button btn-small btn-input"
										type="submit"
										value="Submit"
										:disabled="hasSent"
									>
										Submit
									</button>
								</div>
							</div>
						</form>
						<div class="text-c">
							<p class="text">
								<i
									>Ascentador offers users the ability to submit articles and
									other writings for further publication on this website which
									is open to the public generally. By clicking "submit", you
									acknowledge that any content submitted for publication is your
									own content, that you understand such content may be published
									on our website (though the decision to publish is entirely at
									Ascentador's sole and unreviewable discretion) with authorship
									attributed to you, and that Ascentador may publish to or
									remove from our website your submitted content at any time and
									without prior notice to you. You acknowledge that Ascentador
									cannot guarantee the security of any information you disclose
									through this submission and thus you make such submissions at
									your own risk. Ascentador is not responsible for the content
									or accuracy of any content you submit and which we
									subsequently publish on our website. You assume full
									responsibility for any content you submit.</i
								>
							</p>
						</div>
					</slot>
				</section>
			</div>
		</div>
	</transition>
</template>

<script>
import axios from "axios";

export default {
	name: "ExchangeModal",
	data() {
		return {
			active: false,
			hasSent: false,
			perspective: {
				name: "",
				title: "",
				email: "",
				content: "",
				image_path: "",
			},
			error: {},
			API: process.env.VUE_APP_API,
		};
	},
	methods: {
		open() {
			this.active = true;
			this.hideNavbar();
			this.$emit("stop-scrolling");
		},
		close() {
			this.active = false;
			this.onClosePopUp();
			this.error = {};
			this.$emit("keep-scrolling");
		},
		hideNavbar() {
			this.header.classList.add("hidden");
		},
		onClosePopUp() {
			this.header.classList.remove("hidden");
		},
		async onSubmit() {
			this.hasSent = true;
			let formData = new FormData();
			let uploadedFile = this.$refs.file.files[0];
			formData.append("file", uploadedFile);
			formData.append("folder", "articles");
			try {
				const response = await axios.post(`${this.API}/store-file`, formData, {
					headers: {
						"Content-Type": "multipart/form-data",
					},
				});
				this.perspective.image_path = response.data.file_src;
				await axios
					.post(`${this.API}/article`, this.perspective)
					.then((res) => {
						if (res.status === 201) this.$emit("perspective-was-send");
					});
				this.close();
			} catch (error) {
				console.log("entrando acá", error);
				if (error.response.status === 422) {
					this.error = error.response.data.errors;
				}
			} finally {
				this.hasSent = false;
			}
		},
	},
	mounted() {
		this.header = document.querySelector(".header");
	},
};
</script>

<style lang="scss" scoped>
.modal-bk {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: rgba(0, 0, 0, 0.82);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 99999;
}
.modal {
	padding: 2.2rem 3.4rem 3.2rem 3.4rem;
	max-width: 72rem;
	width: 100%;
	overflow-x: auto;
	display: flex;
	flex-direction: column;
}
.modal-header {
	padding: 1.5rem;
	margin-bottom: 3rem;
	position: relative;
}
.modal-body {
	position: relative;
}
.btn-close {
	width: 3.2rem;
	height: 3.2rem;
	// position: absolute;
	position: relative;
	float: right;
	border: none;
	background: url("../../assets/icon-close.svg") no-repeat center center / 100%
		auto;
	cursor: pointer;
	font-weight: bold;

}

// Transition
.modal-fade-enter,
.modal-fade-leave-to {
	opacity: 0;
}
.modal-fade-enter-active,
.modal-fade-leave-active {
	transition: opacity 0.5s ease;
}
</style>
